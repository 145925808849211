<template>
<div>
  <b-container fluid style="background-image: url('/img/ai-background.jpg'); background-repeat: no-repeat; background-size: cover; height: 478px; margin: 0px;">
    <b-row>
      <b-col style="color: white;">
        <b-container>
          <b-row>
            <b-col>
              <h1 class="kstyle" style="font-size: 42px; font-weight: 500; margin-top: 200px;">Welcome to the AI Regulatory Radar</h1>
              <p style="font-size: 18px;">Curated by a global network of specialists,
                KPMG's AI Regulatory Radar provides insights into the latest AI regulations,
                news and insights from around the world.
              </p>
            </b-col>
          </b-row>
        </b-container>
      </b-col>
    </b-row>
  </b-container>
  <b-container class="mt-4">
    <b-row class="link-cards">
      <b-col lg="12">
        <h2 class="mb-4" style="font-size: 20px; font-weight: bold;">Get Started</h2>
      </b-col>
      <b-col class="mb-5" lg="3" md="6">
        <router-link :to="{ name: 'AiRegulations' }" class="link-card">
          <div class="p-0 m-0" style="background-color: #1E49E2; color: white; height: 220px;">
            <div class="p-0 m-0" style="text-align: center; height: 130px;"><b-icon-folder class="home-icon"></b-icon-folder></div>
            <div class="link-card-link p-3 m-0" style="text-align: center;">Browse regulations</div>
          </div>
        </router-link>
      </b-col>
      <b-col class="mb-5" lg="3" md="6">
        <router-link :to="{ name: 'AiCountry', params: { country: countrySelected } }" class="link-card">
          <div class="p-0 m-0" style="background-color: #1E49E2; color: white; height: 220px;">
            <div class="p-0 m-0" style="text-align: center; height: 130px;"><b-icon-pin-map class="home-icon"></b-icon-pin-map></div>
            <div class="p-3 m-0" style="text-align: center;">Investigate a market</div>
          </div>
        </router-link>
      </b-col>
      <b-col class="mb-5" lg="3" md="6">
        <router-link :to="{ name: 'AiNews', params: {id: 154 }}" class="link-card">
          <div class="p-0 m-0" style="background-color: #1E49E2; color: white; height: 220px;">
            <div class="p-0 m-0" style="text-align: center; height: 130px;"><b-icon-newspaper class="home-icon"></b-icon-newspaper></div>
            <div class="p-3 m-0" style="text-align: center;">Access latest news</div>
          </div>
        </router-link>
      </b-col>
      <b-col class="mb-5" lg="3" md="6">
        <router-link :to="{ name: 'AiInsights' }" class="link-card">
          <div class="p-0 m-0" style="background-color: #1E49E2; color: white; height: 220px;">
            <div class="p-0 m-0" style="text-align: center; height: 130px;"><b-icon-binoculars class="home-icon"></b-icon-binoculars></div>
            <div class="p-3 m-0" style="text-align: center;">Explore Insights</div>
          </div>
        </router-link>
      </b-col>
    </b-row>
    <b-row v-if="aipost">
      <b-col>
        <h2 class="mb-2" style="font-size: 20px; font-weight: bold;">AI on AI</h2>
        <p class="mb-3">Summary of recent developments generated automatically by Gen AI - may contain mistakes</p>
      </b-col>
    </b-row>
    <b-row class="shadow px-3 py-4 bg-white mb-5" v-if="aipost">
      <b-col>
        <h4 class="mb-3" style="font-size: 18px; font-weight: bold;">{{ aipost.name }}</h4>
        <div class="mb-3">{{ aipost.created_at.slice(0,10) }}</div>
        <div>
          <markdown :content="aipost.body" />
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col class="mb-5" lg="8" md="12">
        <h2 style="font-size: 20px; font-weight: bold;">About AI Regulatory Radar</h2>
        <b-container class="shadow p-3 bg-white">
          <b-row>
            <b-col class="text-center" lg="4" sm="12">
              <div>
                <div style="margin: 0 auto; clip-path: circle(50% at 50% 50%); background-image: url('/img/LaurentGobbi.png'); background-position: center; background-size: cover; width: 153px; height: 153px;"></div>
              </div>
              <p class="mb-0"><strong>Laurent Gobbi</strong></p>
              <p class="mb-0"><small>Global Trusted AI & Tech Risk Leader</small></p>
              <p><small>KPMG in France</small></p>
            </b-col>
            <b-col lg="8" sm="12">
              <div>
                <p>Artificial intelligence (AI) has been described as the ‘internet moment of our time’.
                  As the technology evolves, it increasingly has the power to transform our lives.
                  The rapid advancement of new technology combined with increased global uncertainty drives many CEOs
                  to start thinking seriously about embracing and embedding AI into future growth strategies.
                  But change at such pace requires agility and innovative thinking.
                  How people prepare for that change is crucial. Scalability and speed are dependent on those around us embracing and adopting the technology.</p>
                <p>
                  AI Regulatory Radar is a tool that helps you to understand the regulatory landscape for AI in different countries.
                  It provides an inventory of AI related regulations, news and KPMG insights from around the world.
                </p>
              </div>
            </b-col>
          </b-row>
        </b-container>
        <h2 class="mt-5" style="font-size: 20px; font-weight: bold;">KPMG Insights</h2>
        <p>Recent KPMG publications</p>
        <div v-if="!loadingArticles && articles.length > 0">
          <b-container class="shadow p-5" style="background-color: #1E49E2; color: white;">
            <b-row>
              <b-col lg="8" sm="12">
                <div><b-link :to="{ name: 'AiInsight', params: {id: article.id }}" style="color: white;"><strong>{{article.title}}</strong></b-link></div>
                <div class="mb-2"><small>{{moment(article.approved_at).format('YYYY-MM-DD')}} by {{ article.approved_by }}</small></div>
                <div>{{ article.summary }}</div>
              </b-col>
              <b-col class="text-center" lg="4" sm="12">
                <div>
                  <div :style="article.style"></div>
                </div>
              </b-col>
            </b-row>
          </b-container>
          <div v-for="item in articles" :key="'articles'+item.id" class="my-4">
            <b-container class="shadow p-3 bg-white">
              <b-row>
                <b-col>
                  <div><b-link :to="{ name: 'AiInsight', params: {id: item.id }}"><strong>{{item.title}}</strong></b-link></div>
                  <div class="mb-2"><small>{{moment(item.approved_at).format('YYYY-MM-DD')}} by {{ item.approved_by }}</small></div>
                  <div>{{ item.summary }}</div>
                </b-col>
              </b-row>
            </b-container>
          </div>
          <div class="mt-4 mb-2"><b-link :to="{ name: 'AiInsights' }"><strong>More Insights</strong></b-link></div>
        </div>
        <div class="my-3" v-if="loadingArticles">
          <b-spinner label="loading insights..." />
          loading insights...
        </div>
        <h2 class="mt-5" style="font-size: 20px; font-weight: bold;">Contact us</h2>
        <p>Provide feedback, request features or support</p>
        <b-container class="shadow p-3 bg-white">
          <b-row>
            <b-col>
              <contact-form subject="AI Regulatory Radar Feedback" />
            </b-col>
          </b-row>
        </b-container>
      </b-col>
      <b-col lg="4" md="12">
        <h2 style="font-size: 20px; font-weight: bold;">News</h2>
        <p>Recent AI industry updates</p>
        <div class="shadow py-4 px-3 bg-white" v-if="!loading">
          <div class="my-3" v-for="(item, index) in news" :key="'news-' + index">
            <div>
              <router-link :to="{ name: 'AiNewsItem', params: { id: item.id }}">
                <div><strong>{{item.name}}</strong></div>
                <div><small>{{ item.date.slice(0,10) }} - {{ item.countryName }} - {{ item.publisher }}</small></div>
              </router-link>
            </div>
          </div>
          <div>
            <router-link class="mt-4 mb-2" :to="{ name: 'AiNews', params: {id: 154 }}"><strong>More News</strong></router-link>
          </div>
        </div>
      </b-col>
    </b-row>
  </b-container>
</div>
</template>

<script>
import moment from 'moment'

import contactForm from '@/components/ContactForm.vue'
import Markdown from '@/components/Markdown.vue'
import { BIconBinoculars, BIconFolder, BIconNewspaper, BIconPinMap } from 'bootstrap-vue'

function getUsernameFromEmail (email) {
  const match = email.match(/^[^@]+/)
  return match ? match[0].replace(/\./g, '') : null
}

export default {
  name: 'AiHome',
  components: {
    BIconBinoculars,
    BIconFolder,
    BIconNewspaper,
    BIconPinMap,
    contactForm,
    Markdown
  },
  computed: {
    countrySelected: {
      get () {
        return this.$store.state.countryCPRD1
      },
      set (payload) {
        this.$store.commit('setCountryCPRD1', payload)
      }
    },
    countrySelected2: {
      get () {
        return this.$store.state.countryCPRD2
      },
      set (payload) {
        this.$store.commit('setCountryCPRD2', payload)
      }
    },
    user: {
      get () {
        return this.$store.state.user
      }
    }
  },
  data () {
    return {
      aipost: null,
      article: null,
      articles: [],
      loading: true,
      loadingArticles: true,
      moment: moment,
      news: []
    }
  },
  created: async function () {
    this.$store.commit('setBrand', 'AI Regulatory Radar')
    document.title = "AI Regulatory Radar"
    const aiposts = await this.$Amplify.API.get('cosmos', `/aiposts/topic/154/limit/1/offset/0`)
    if (aiposts.length > 0) {
      this.aipost = aiposts[0]
    }
    this.news = await this.$Amplify.API.get('cosmos', '/news/topic/154/standardformat/limit/20')
    this.loading = false
    const articles = await this.$Amplify.API.get('cosmos', `/articles/topic/154/limit/5`)
    articles.forEach(x => {
      const img = getUsernameFromEmail(x.approved_by)
      x.img = img
      x.style = `margin: 0 auto; clip-path: circle(50% at 50% 50%); background-image: url('/img/${img}.jpg'); background-position: center; background-size: cover; width: 153px; height: 153px;`
    })
    const article = articles.shift()
    this.article = article
    this.articles = articles
    console.log(this.articles)
    this.loadingArticles = false
  },
  methods: {
  },
  watch: {
  }
}
</script>

<style lang="scss" scoped>
.carousel-caption {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%; /* Ensure the div takes full height of the slide */
  padding-top: 1rem; /* Optional: add some padding to the top */
}

.carousel-content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%; /* Ensure the div takes full height of the slide */
  padding-top: 1rem; /* Optional: add some padding to the top */
}

.home-icon {
  color: white;
  height: 90px;
  width: 90px;
  margin: 20px;
}
.link-card {
  font-size: 16px;
  font-weight: bold;
}

.link-card div:hover {
  font-size: 18px;
  font-weight: bold;
  text-decoration: none;
}

.link-cards a:hover {
    text-decoration: none;
}
</style>
